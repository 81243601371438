import React, { useState, useRef } from 'react'
import {
  Container,
  Row,
  Col,
  Media,
  ButtonGroup,
  ToggleButton,
  Button,
  Form,
} from 'react-bootstrap'
import logo from './assets/logo.png'
// import axios from 'axios'

const format = (number, n, x) => {
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
  return number.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&.')
}

const Seleccion = ({ name, valor, title, list, handleChange }) => {
  return (
    <Form>
      <Form.Label>{title}:</Form.Label>
      <Form.Group controlId={name}>
        <Form.Control
          as='select'
          size='sm'
          onChange={(e) => handleChange(e)}
          value={valor}
        >
          {list.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </Form>
  )
}
const App = () => {
  const dataFocus = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [estimado, setEstimado] = useState(-1)
  const [sex, setSex] = useState('1')
  const [age, setAge] = useState('65')
  const [cotizaciones, setCotizaciones] = useState('1')
  const [remuneracion, setRemuneracion] = useState('1')
  const [saldo, setSaldo] = useState('1000')
  const [incorpValue, setIncorpValue] = useState(1)
  const [tipoPensionValue, setTipoPensionValue] = useState(1)
  const [modalidadValue, setModalidadValue] = useState(8)

  const sexos = [
    { name: 'Femenino', value: '1' },
    { name: 'Masculino', value: '2' },
  ]
  const incorp = [
    {
      name: 'Trabajador dependiente del sistema de capitalización individual',
      value: 1,
    },
    {
      name: 'Trabajador independiente del sistema de capitalización individual',
      value: 2,
    },
    { name: 'Trabajador del antiguo sistema', value: 3 },
    {
      name:
        'Usuario del sistema de capitalización individual no afiliado a la Administradora',
      value: 4,
    },
    { name: 'Afiliado voluntario', value: 5 },
    { name: 'Afiliado voluntario por OCE', value: 6 },
  ]
  const tipoPension = [
    { name: 'Vejez edad', value: 1 },
    { name: 'Vejez anticipada', value: 2 },
    { name: 'Vejez edad por trabajo pesado', value: 3 },
  ]
  const modalidad = [
    {
      name:
        'Renta Vitalicia Inmediata simple, sin condiciones especiales de cobertura',
      value: 1,
    },
    {
      name:
        'Renta Vitalicia Inmediata garantizada, con condiciones especiales de cobertura',
      value: 2,
    },
    {
      name:
        'Renta Temporal con Renta Vitalicia Diferida simple, sin condiciones especiales de cobertura',
      value: 3,
    },
    {
      name:
        'Renta Temporal con Renta Vitalicia Diferida garantizada, con condiciones especiales de cobertura',
      value: 4,
    },
    {
      name:
        'Renta Vitalicia inmediata simple, con retiro programado, sin condiciones especiales de cobertura',
      value: 5,
    },
    {
      name:
        'Renta Vitalicia inmediata garantizada, con retiro programado, con condiciones especiales de cobertura',
      value: 6,
    },
    { name: 'Retiro Programado', value: 8 },
    {
      name: 'Retiro Programado con documento Bono no endosado ni transado',
      value: 9,
    },
    { name: 'Retiro Programado con pago preliminar', value: 10 },
    { name: 'No corresponde informar', value: 11 },
    {
      name: 'Pensión Cubierta por el seguro con anterioridad a la Ley 18.646',
      value: 12,
    },
  ]

  const getEstimacion = async () => {
    const token = 'c5Gv2PUtb6cVKpsTQazoh2XYvpgTf4EL'
    try {
      fetch(
        // 'https://thingproxy.freeboard.io/fetch/http://13.82.195.123/api/v1/service/deploy-prediccion-monto/score',
        'https://cors-anywhere.herokuapp.com/http://13.82.195.123/api/v1/service/deploy-prediccion-monto/score',
        {
          method: 'POST',
          // mode: 'no-cors',
          // withCredentials: true, // <-- ADD THIS
          // credentials: 'include', // <-- AND THIS
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Upgrade-Insecure-Requests': 1,
          },
          body: JSON.stringify({
            Inputs: {
              WebServiceInput0: [
                {
                  sexo: sex,
                  EDAD: age,
                  N_CO: cotizaciones,
                  REM_IMP_ULT_COT: remuneracion,
                  TIPO_INCORP: incorpValue,
                  TIPO_PENSIONV: tipoPensionValue,
                  MOD_SEL_AL_PENSIONARSE: modalidadValue,
                  SALDO_CCICO: saldo,
                },
              ],
            },
            GlobalParameters: {},
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          try {
            console.log(data)
            setEstimado(data.Results.WebServiceOutput0[0].predicted_price)
          } catch (e) {
            setEstimado(-2)
          }
        })
    } catch (err) {
      setEstimado(-2)
    }
  }
  const handleSubmit = () => {
    setIsLoading(false)
    console.log(
      sex,
      age,
      cotizaciones,
      remuneracion,
      saldo,
      incorpValue,
      tipoPensionValue,
      modalidadValue
    )
    if (age === 1) {
      setEstimado(-1)
    } else {
      // setEstimado(1000000)
      window.scrollTo(0, document.body.scrollHeight)
      const d = getEstimacion()
      console.log(d)
      // dataFocus.current.focus()
    }
  }
  const handleAgeChange = (e) => {
    if (Number(e.currentTarget.value) || e.currentTarget.value === '') {
      setAge(e.currentTarget.value)
      setEstimado(-1)
    }
  }
  const handleCotizacionesChange = (e) => {
    if (Number(e.currentTarget.value) || e.currentTarget.value === '') {
      setCotizaciones(e.currentTarget.value)
      setEstimado(-1)
    }
  }
  const handleRemuneracionChange = (e) => {
    if (Number(e.currentTarget.value) || e.currentTarget.value === '') {
      setRemuneracion(e.currentTarget.value)
      setEstimado(-1)
    }
  }
  const handleSaldoChange = (e) => {
    if (Number(e.currentTarget.value) || e.currentTarget.value === '') {
      setSaldo(e.currentTarget.value)
      setEstimado(-1)
    }
  }
  const handleIncorpChange = (e) => {
    setIncorpValue(e.currentTarget.value)
    setEstimado(-1)
  }
  const handleTipoPensionChange = (e) => {
    setTipoPensionValue(e.currentTarget.value)
    setEstimado(-1)
  }
  const handleModalidadChange = (e) => {
    setModalidadValue(e.currentTarget.value)
    setEstimado(-1)
  }
  return (
    <>
      <style type='text/css'>{`
      .container-fluid {
        max-width: 375px;
        margin: auto;
      }
      .label {
        padding: 3px 10px 0px 0px;
        // font-weight: bold;
     }
      `}</style>
      <Container fluid>
        <Row className='justify-content-center'>
          <Col>
            <Media>
              <img src={logo} alt='Subsecretaría de previsión social' />
            </Media>
            <h2>Asistente de pensiones:</h2>
            <p>
              Queremos ayudarte en la planificación de tu jubilación, por favor
              indícanos tus datos:
            </p>
            <ButtonGroup toggle size='sm'>
              <span className='label'>Sexo:</span>
              {sexos.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  type='radio'
                  variant={sex === radio.value ? 'danger' : 'outline-danger'}
                  name='sexForm'
                  value={radio.value}
                  checked={sex === radio.value}
                  onChange={(e) => setSex(e.currentTarget.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
            <br />
            <br />
            <Form>
              <Form.Label>Edad:</Form.Label>
              <Form.Group controlId='formAge'>
                <Form.Control
                  as='input'
                  type='text'
                  size='sm'
                  onChange={(e) => handleAgeChange(e)}
                  value={age}
                />
              </Form.Group>
            </Form>
            <Form>
              <Form.Label>Nro de cotizaciones:</Form.Label>
              <Form.Group controlId='formCotiza'>
                <Form.Control
                  as='input'
                  type='text'
                  size='sm'
                  onChange={(e) => handleCotizacionesChange(e)}
                  value={cotizaciones}
                />
              </Form.Group>
            </Form>
            <Form>
              <Form.Label>Remuneración imponible últ. cotización:</Form.Label>
              <Form.Group controlId='formRemu'>
                <Form.Control
                  as='input'
                  type='text'
                  size='sm'
                  onChange={(e) => handleRemuneracionChange(e)}
                  value={remuneracion}
                />
              </Form.Group>
            </Form>
            <Seleccion
              name='formIncorp'
              title='Tipo de incorporación'
              list={incorp}
              handleChange={handleIncorpChange}
              value={incorpValue}
            />
            <Seleccion
              name='formPension'
              title='Tipo pensión de vejez'
              list={tipoPension}
              handleChange={handleTipoPensionChange}
              value={tipoPensionValue}
            />
            <Seleccion
              name='formModalidad'
              title='Modalidad de Pensión seleccionada al pensionarse'
              list={modalidad}
              handleChange={handleModalidadChange}
              value={modalidadValue}
            />
            <Form>
              <Form.Label>Saldo en cuenta individual ($):</Form.Label>
              <Form.Group controlId='formSaldo'>
                <Form.Control
                  as='input'
                  type='text'
                  size='sm'
                  onChange={(e) => handleSaldoChange(e)}
                  value={saldo}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              size='sm'
              variant='primary'
              disabled={isLoading}
              onClick={!isLoading ? handleSubmit : null}
            >
              {isLoading ? 'Loading...' : 'Calcular pensión estimada'}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <br />
            <input
              size={35}
              type='text'
              value={
                estimado < 0
                  ? estimado === -2
                    ? 'Error'
                    : ''
                  : 'Pensión estimada $ ' + format(estimado)
              }
              ref={dataFocus}
              onChange={(e) => {
                e.currentTarget.value = 'Pensión estimada $ ' + format(estimado)
              }}
              style={{ border: '0', fontWeight: 'bold' }}
            />
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default App
